import { MUI_THEME } from '@/modules/Theme/constant.js';
import {
  BUTTON_VARIANTS,
  ICON_BUTTON_VARIANTS,
} from '@/utils/materialTheme/constant.js';

export const MuiIconButton = {
  styleOverrides: {
    root: ({ theme, ...button }) => {
      const isLight = theme.palette.mode === MUI_THEME['light-mode'];

      if (button?.ownerState.variant === BUTTON_VARIANTS.SAVE) {
        return {
          background: isLight ? 'var(--light-grey-1-color)' : '#E0E0E0',
          borderRadius: '50px !important',
          color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          textTransform: 'none',
          padding: '0.6rem',
          ':hover': {
            background: isLight
              ? 'var(--bg-light-grey-3)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: 'var(--bg-light-grey-2)',
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.ICON_BUTTON) {
        return {
          background: isLight ? 'var(--bg-light-gray-1)' : '#E0E0E0',
          borderRadius: '8px !important',
          color: isLight ? 'var(--text-bg-black)' : 'var(--bg-white)',
          textTransform: 'none',
          padding: '1.2rem', // TODO: move to size
          ':hover': {
            background: isLight
              ? 'var(--bg-light-grey-3)'
              : 'var(--surface-light-grey-3)',
            color: isLight ? 'var(--text-primary)' : 'var(--bg-white)',
          },
          ':disabled': {
            color: 'var(--text-secondary)',
            background: 'var(--bg-light-grey-2)',
          },
        };
      }
      if (button?.ownerState.variant === ICON_BUTTON_VARIANTS.V3_GHOST_DARK) {
        return {
          background: isLight ? 'transparent' : 'transparent',
          borderRadius: '8px !important',
          border: isLight ? 'none' : 'none',
          color: isLight ? 'var(--color-black)' : 'var(--color-black)',
          textTransform: 'none',
          ':hover': {
            background: 'var(--v3-border-dark-grey-color)',
            color: 'var(--color-white)',
            '& > svg > path': {
              stroke: 'var(--color-white)',
            },
          },
          ':disabled': {
            background: 'var(--gray-900)',
            color: 'var(--gray-700)',
          },
        };
      }
      if (
        button?.ownerState.variant === ICON_BUTTON_VARIANTS.V3_SECONDARY_DARK
      ) {
        return {
          background: 'var(--gray-900)',
          borderRadius: '8px !important',
          color: 'var(--color-white)',
          textTransform: 'none',
          ':hover': {
            background: 'var(--gray-950)',
            color: 'var(--color-white)',
          },
          ':disabled': {
            color: 'var(--gray-700)',
            background: 'var(--gray-900)',
          },
        };
      }
      if (button?.ownerState.variant === BUTTON_VARIANTS.V3_ICON_TRANSPARENT) {
        return {
          background: 'transparent',
          borderRadius: '8px !important',
          color: isLight ? 'var(--text-bg-black)' : 'var(--bg-white)',
          textTransform: 'none',
          ':hover': {},
          ':disabled': {},
        };
      }

      return {
        background: 'transparent',
        borderRadius: '50px !important',
        padding: '0.6rem',
        ':hover': {},
        ':disabled': {
          color: 'var(--text-secondary)',
          background: 'transparent',
          '& > svg > path': {
            fill: 'var(--text-secondary)',
          },
        },
      };
    },
    sizeXsmall: { padding: '0px' },
    sizeSmall: {
      padding: '4px',
    },
    sizeXm: { padding: '8px' },
    sizeMedium: { padding: '6px' },
    sizeLarge: {},
  },
};
