import httpService from '@/api/http.service.js';
import { getQueryParameters } from '@/utils/api.js';
import { UPLOAD_REQUEST_TIMER } from '@/utils/constant.js';
const ROUTE = 'scenes';
export const getScenes = (params) => httpService.get(ROUTE, { params });
export const getSceneTypes = (params) =>
  httpService.get(`${ROUTE}/scene-types`, { params });
export const getSnapPoints = (params) =>
  httpService.get(`${ROUTE}/snap-points`, { params });
export const uploadBackground = (sceneId, lighting, body) =>
  httpService.post(`${ROUTE}/upload-background/${sceneId}/${lighting}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
  });
export const uploadSvgMask = (sceneId, surfaceId, body) =>
  httpService.post(`${ROUTE}/upload-svg-mask/${sceneId}/${surfaceId}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
  });
export const surfacesToScene = (sceneId, body) =>
  httpService.put(`${ROUTE}/surfaces-to-scene-list/${sceneId}`, body);
export const changeSurfaceFlags = (sceneId, surfaceId, params) =>
  httpService.patch(
    `${ROUTE}/surface-to-scene-flags/${sceneId}/${surfaceId}`,
    null,
    {
      params: getQueryParameters(params),
    },
  );

export const addScene = (body) => httpService.post(ROUTE, body);
export const editScene = (id, body) => httpService.put(`${ROUTE}/${id}`, body);
export const getSceneById = (id) => httpService.get(`${ROUTE}/${id}`);
export const uploadScene = (id, body, onProgress) =>
  httpService.post(`${ROUTE}/upload/${id}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
    onUploadProgress: onProgress,
  });
export const uploadSceneFile = (typeId, body, onProgress) =>
  httpService.post(`render-types/upload-scene-file/${typeId}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
    onUploadProgress: onProgress,
  });
