import { create } from 'zustand';

import { MAX_LIMIT } from '@/modules/Permissions/store/index.js';
import { getTasks } from '@/modules/Tasks/api/index.js';
import { getQueryParameters } from '@/utils/api.js';
import { getQuery } from '@/utils/common.js';
import { SORT_ORDER } from '@/utils/constant.js';
export const PER_PAGE = 15;

const initialState = {
  tasks: [],
  allTasks: [],
  count: 0,
  page: 1,
  title: '',
  task: '',
  stage: '',
  status: '',
  order_by: 'modified',
  order_direction: SORT_ORDER.DESC,
};

export const useTasksList = create((set, get) => ({
  ...initialState,
  fetchTasks: async () => {
    const {
      page,
      order_by,
      order_direction,
      title: search,
      task,
      stage,
      status,
    } = get();
    getQuery();
    const params = getQueryParameters({
      ...getQuery(page, order_by, order_direction),
      search,
      name: task,
      stage__in: stage ? [stage] : [],
      status__in: status ? [status] : [],
    });

    const response = await getTasks(params);
    set({
      tasks: response.data?.items,
      count: response.data?.count,
    });
    return get();
  },
  fetchAllTasks: async () => {
    const response = await getTasks(
      getQueryParameters({
        offset: 0,
        limit: MAX_LIMIT,
      }),
    );

    set({
      allTasks: response.data?.items,
    });
    return get();
  },
  setPage: (page) => {
    set({
      page,
    });
  },
  setOrderBy: (order_by) => {
    set({
      page: 1,
      order_by,
    });
  },
  setOrderDirection: (order_direction) => {
    set({
      order_direction,
    });
  },
  setTitle: (title) => {
    set({ title, page: 1 });
  },
  setStage: (stage) => {
    set({ stage, page: 1 });
  },
  setTask: (task) => {
    set({ task, page: 1 });
  },
  setStatus: (status) => {
    set({ status, page: 1 });
  },
  clean: () => {
    set(initialState);
  },
}));
