import _ from 'lodash';
import { create } from 'zustand';

import { getPermissions } from '@/modules/Permissions/api/index.js';
import { SORT_ORDER } from '@/utils/constant.js';
import { stringSort } from '@/utils/sort.js';
export const PER_PAGE = 15;
export const MAX_LIMIT = 300;

const initialState = {
  permissionList: [],
  count: 0,
  page: 1,
  order_by: 'modified',
  order_direction: SORT_ORDER.DESC,
  loading: false,
};

const getQuery = (page, order_by, order_direction, limit) => {
  return _.omitBy(
    {
      offset: (page - 1) * PER_PAGE || 0,
      limit: limit || PER_PAGE,
      order_by,
      order_direction,
    },
    (v) => _.isUndefined(v) || _.isNull(v) || v === '',
  );
};

export const usePermissionList = create((set, get) => ({
  ...initialState,
  fetchAllPermissions: async () => {
    set({
      loading: true,
    });
    try {
      const params = getQuery(1, null, null, MAX_LIMIT);
      const permissionResponse = await getPermissions(params);

      set({
        loading: false,
        permissionList: permissionResponse.data?.items.sort(
          stringSort('title'),
        ),
        count: permissionResponse.data?.count,
      });
    } finally {
      set({
        loading: false,
      });
    }
    return get();
  },
  setPage: (page) => {
    set({
      page,
    });
  },
  setOrderBy: (order_by) => {
    set({
      page: 1,
      order_by,
    });
  },
  setOrderDirection: (order_direction) => {
    set({
      order_direction,
    });
  },
}));
