import { useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import Cross from '@/assets/svg/Cross.svg?react';
import Copy from '@/assets/svg/design-v3/CopyGrey.svg?react';
import {
  SNACKBAR_VARIANT,
  useSnackBarStore,
} from '@/modules/SnackBar/store/index.js';
import { NOTIFICATION_MESSAGES } from '@/utils/constant.js';

const NOTIFICATION_TIMEOUT = 15000;

const SnackbarManager = () => {
  const snackbars = useSnackBarStore((state) => state.snackbars);
  const removeSnackbar = useSnackBarStore((state) => state.removeSnackbar);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const shownSnackbarsRef = useRef(new Set());

  const handleCopyClick = async (e, text) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error(NOTIFICATION_MESSAGES.COPY_ERROR, err);
    }
  };

  useEffect(() => {
    const newSnackbars = snackbars.filter(
      ({ id }) => !shownSnackbarsRef.current.has(id),
    );

    newSnackbars.forEach(({ id, text, severity }) => {
      const uniqueKey = uuidv4(); // Generate a unique key for each snackbar

      enqueueSnackbar(text, {
        key: uniqueKey, // Use the unique key
        variant: severity,
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'left',
          marginBottom: 'auto',
        },
        disableWindowBlurListener: true,
        autoHideDuration:
          severity === SNACKBAR_VARIANT.ERROR ? null : NOTIFICATION_TIMEOUT,
        hideIconVariant: true,
        onClose: () => {
          removeSnackbar(id);
          shownSnackbarsRef.current.delete(id);
        },
        className: 'alert-snackbar', // Apply the custom CSS class
        action: (key) => (
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            {severity === SNACKBAR_VARIANT.ERROR && (
              <IconButton
                size='small'
                aria-label='copy'
                color='inherit'
                style={{
                  background: 'white',
                  width: '24px',
                  height: '24px',
                  padding: '4px',
                }}
                onClick={(e) => handleCopyClick(e, text)}
              >
                <Copy />
              </IconButton>
            )}
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              style={{
                background: 'white',
                width: '24px',
                height: '24px',
                padding: '4px',
              }}
              onClick={() => {
                closeSnackbar(key);
                removeSnackbar(id);
                shownSnackbarsRef.current.delete(id);
              }}
            >
              <Cross />
            </IconButton>
          </div>
        ),
      });

      shownSnackbarsRef.current.add(id);
    });
  }, [snackbars, enqueueSnackbar, removeSnackbar]);

  return null;
};

export default SnackbarManager;
