import { SLIDER_VARIANTS } from '@/utils/materialTheme/constant.js';

export const MuiSlider = {
  defaultProps: {
    componentsProps: {
      thumb: {},
    },
  },
  styleOverrides: {
    root: {
      color: 'var(--color-black)',
      height: '1px',
      padding: 0,
    },
    thumb: (props) => {
      const variant = props?.ownerState?.variant;
      if (variant === SLIDER_VARIANTS.WHITE) {
        return {
          width: '4px',
          height: '4px',
          backgroundColor: 'var(--color-white)',
          '&:hover, &.Mui-focusVisible, &.Mui-active': {
            boxShadow: 'none',
          },
        };
      }

      return {
        width: '4px',
        height: '4px',
        backgroundColor: 'var(--color-black)',
        boxShadow: 'none',
        '&::after': {
          height: '15px',
        },
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
          width: '4px',
          height: '4px',
        },
        '&:hover, &.Mui-focusVisible, &.Mui-active': {
          boxShadow: 'none',
        },
        '&:hover, &.Mui-active': {
          width: '12px',
          height: '12px',
        },
      };
    },
    track: (props) => {
      const variant = props?.ownerState?.variant;

      if (variant === SLIDER_VARIANTS.WHITE) {
        return {
          height: '2px',
          border: 'none',
          background: 'var(--color-white)',
        };
      }

      return {
        height: '2px',
        border: 'none',
        background: 'var(--color-silver-light)',
      };
    },
    rail: (props) => {
      const variant = props?.ownerState?.variant;

      if (variant === SLIDER_VARIANTS.WHITE) {
        return { height: '2px', background: 'var(--color-silver-light)' };
      }

      return {
        height: '2px',
        background: 'var(--light-gray-3)',
      };
    },
  },
};
