import httpService from '@/api/http.service.js';
import { getQueryParameters } from '@/utils/api.js';
const ROUTE = 'materials';
const ETL_ROUTE = 'etl';
export const getMaterials = (params) => httpService.get(ROUTE, { params });
export const getMaterialsByRenderTasks = (params) =>
  httpService.get(`${ROUTE}/` + `by-render-tasks`, { params });
export const declineReasons = (id, body) =>
  httpService.patch(`${ROUTE}/decline-reasons/${id}`, body);
export const updateOpacity = (body, params) => {
  return httpService.patch(`${ROUTE}/opacity`, body, {
    params: getQueryParameters(params),
  });
};

export const getDeclineReasons = () =>
  httpService.get(`${ROUTE}/decline-reasons`);
export const getImageDeclineReasons = () =>
  httpService.get(`material-images/decline-reasons`);
export const declineMaterialImageReasons = (body) =>
  httpService.patch(`material-images/decline-reasons`, body);
export const startSyncEtl = (params) =>
  httpService.get(`${ETL_ROUTE}/start-sync-materials`, {
    params: getQueryParameters(params),
    timeout: undefined,
  });
export const getSpaceApplications = () =>
  httpService.get(`${ROUTE}/space-applications`);
export const addMaterial = (body) => httpService.post(ROUTE, body);
export const addMaterialPreset = (params) => {
  return httpService.patch(`${ROUTE}/add-preset`, null, {
    params: getQueryParameters(params),
  });
};
export const addMaterialPresetClass = (params) => {
  return httpService.patch(`${ROUTE}/add-preset-class`, null, {
    params: getQueryParameters(params),
  });
};
export const editMaterial = (id, body) =>
  httpService.put(`${ROUTE}/${id}`, body);
export const getMaterialById = (id) => httpService.get(`${ROUTE}/${id}`);
export const changeMaterialDigitalStatus = (params) =>
  httpService.patch(`${ROUTE}/change-digital-status`, null, {
    params: getQueryParameters(params),
  });
export const changeMaterialRenderType = (params) =>
  httpService.patch(`${ROUTE}/change-render-type`, null, {
    params: getQueryParameters(params),
  });

export const addAssignee = (params) =>
  httpService.patch(`${ROUTE}/distribute-assignees`, null, {
    params: getQueryParameters(params),
  });
