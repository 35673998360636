export const SELECT_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_MEDIUM: 'x-medium',
};
export const MUI_BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XSMALL: 'xsmall', // Custom size
  XXSMALL: 'xxsmall',
  XXXSMALL: 'xxxsmall',
};

export const MUI_TEXT_FIELD_SIZES = {
  SMALL: 'small',
  X_SMALL: 'x_small',
  MEDIUM: 'medium',
  SM_32: 'sm_32',
  LARGE: 'large', // Note: 'large' is not a standard size in MUI, but you can define it if needed.
};

export const MUI_SLIDER_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};
