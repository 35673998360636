import httpService from '@/api/http.service.js';
import { UPLOAD_REQUEST_TIMER } from '@/utils/constant.js';
const ROUTE = 'render-types';
export const getRenderTypes = (params) => httpService.get(ROUTE, { params });
export const getRenderTypeId = (id) => httpService.get(`${ROUTE}/${id}`);

export const addRenderType = (body, onProgress) =>
  httpService.post(ROUTE, body, {
    timeout: UPLOAD_REQUEST_TIMER,
    onUploadProgress: onProgress,
  });
export const editRenderType = (id, body, onProgress) =>
  httpService.put(`${ROUTE}/${id}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
    onUploadProgress: onProgress,
  });
export const uploadMap = (type_id, map_type, body) =>
  httpService.post(`${ROUTE}/upload-map/${type_id}/${map_type}`, body, {
    timeout: UPLOAD_REQUEST_TIMER,
  });
